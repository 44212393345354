/* eslint-disable no-shadow */
export const GuestInfoFields = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email Address',
  address: 'Home Address', // consider adding the 'includes country, city, ...' part
  phoneNumber: 'Cell Phone Number',
  emergencyContact: 'Emergency Contact Cell Number',
  // TODO add the 'Guest Only' tag to the end of these?
  rentalAddress: 'Rental Address',
  arrivalDate: 'Arrival Date',
  departureDate: 'Departure Date',
  numberGuests: 'Number of Guests',
} as const;

export const VehicleInfoFields = {
  make: 'Vehicle Make',
  vehicleModel: 'Vehicle Model',
  type: 'Vehicle Type',
  year: 'Vehicle Year',
  color: 'Vehicle Color',
  licensePlate: 'License Plate',
  fleetNumber: 'Vehicle Fleet Number',
  licensePlateState: 'License plate state',
  primaryDriverName: 'Primary Driver Name',
  licensePrimaryDriver: 'Primary Driver\'s License #',
  destination: 'Destination Address',
  employeeList: 'Employees List',
  name: 'Guest Name',
  completeLater: 'Complete Later (Rental Car)',
  vehicleSelect: 'Vehicle Select',
  driverSelect: 'Driver Select',
  employeeSelectList: 'Employees List (selected from employees in my profile)',
} as const;

// this is a horrible name. Please rename it something better
export const InfoConfigValues = {
  required: 'required',
  optional: 'optional',
  disabled: 'disabled',
};

export const EducationInfoFields = {
  // state define if Education is required, optional or disabled
  // I decided to use InfoConfigValues since they suits pretty good.
  state: InfoConfigValues.required,
  englishEducationEnabled: false,
  spanishEducationEnabled: false,
} as const;

export const DocumentInfoFields = {
  carInsurance: 'Car Insurance',
  businessLicense: 'Business License',
  liabilityLicense: 'Liability License',
  documents: [],
} as const;

export const VendorInfoFields = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email Address',
  businessName: 'Business Name',
  businessAddress: 'Business Address',
  cellPhone: 'Cell Phone Number',
  businessPhone: 'Business Phone Number',
};
export type VendorInfoFieldsType = typeof VendorInfoFields;

export const EmployeeInfoFields = {
  firstName: 'First Name',
  lastName: 'Last Name',
  cellPhone: 'Cell Phone Number',
  email: 'Email Address',
  jobTitle: 'Job Title',
  employeePicture: 'Employee Picture',
  driverLicenseImage: 'Driver License Image',
  driverLicenseScan: 'Driver License Scan',
};
export type EmployeeInfoFieldsType = typeof EmployeeInfoFields;

export const PosOptions = {
  stripe: 'stripe',
};

export enum FeeAmountTypes {
  percentage,
  fixedAmount
}

export enum FeeTypes {
  perProduct,
  perTransaction,
}

export enum PinCodePolicies {
  off,
  pinAndCard,
  pinOnly,
}

export const Portals = {
  vendor: 'vendor',
  guest: 'guest',
  resident: 'resident',
};

export enum DurationTypes {
  // Number of days from date of purchase that pass is valid
  purchaseOnly,

  // pass creator can specify number of days from date of purchase
  // that a pass can be activated
  // AND
  // the number of days after being activated the pass is valid for
  purchaseAndActivation,

  // maximum number of days allowed for user-specified date range
  maxValidDays,
}
