import { gql, TypedDocumentNode, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';

const SET_PASS_ORDER: TypedDocumentNode<
  {
    setPassGroupOrder: backendResponse<undefined>;
  },
  {
    passInfoList: string[];
    passGroupId: string;
  }
> = gql`
mutation SetPassGroupOrder($passInfoList: [String!]!, $passGroupId: String!) {
  setPassGroupOrder(passInfoList: $passInfoList, passGroupId: $passGroupId) {
    success
    error
  }
}
`;

export function useGroupOrder(initialList?: string[]) {
  const [passInfoList, setPassInfoList] = useState<string[]>(initialList || []);

  // sync initialList changes
  useEffect(() => {
    if (initialList) {
      setPassInfoList(initialList);
    }
  }, [JSON.stringify(initialList)]);

  const [doEdit] = useMutation(SET_PASS_ORDER);

  const addStyle = (ev: React.DragEvent<HTMLDivElement>, isLast?: boolean) => {
    const { target } = ev;
    if (target instanceof HTMLDivElement) {
      if (isLast) {
        target.style.borderBottomStyle = 'solid';
        target.style.borderBottomColor = 'var(--secondary-color)';
      } else {
        target.style.borderTopStyle = 'solid';
        target.style.borderTopColor = 'var(--secondary-color)';
      }
    }
  };

  const removeStyle = (ev: React.DragEvent<HTMLDivElement>) => {
    const { target } = ev;
    if (target instanceof HTMLDivElement) {
      target.style.borderTopStyle = 'none';
      target.style.borderBottomStyle = 'none';
    }
  };

  const dragStartHandler = (passInfoId: string, idx: number) => (ev: React.DragEvent<HTMLDivElement>) => {
    ev.dataTransfer.setData('text/json', JSON.stringify({ passInfoId, idx }));
  };

  const dragOverHandler = (isLast?: boolean) => (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    addStyle(ev, isLast);
  };

  const dragLeaveHandler = (ev: React.DragEvent<HTMLDivElement>) => {
    removeStyle(ev);
  };

  const dragDropHandler = (passInfoId: string, idx: number) => (ev: React.DragEvent<HTMLDivElement>) => {
    removeStyle(ev);

    const {
      passInfoId: movedPassInfoId,
    } = JSON.parse(ev.dataTransfer.getData('text/json'));

    setPassInfoList((prev) => {
      const res = prev
        .filter((piid) => piid !== movedPassInfoId);
      res.splice(idx, 0, movedPassInfoId);
      return res;
    });
  };

  const revert = () => setPassInfoList(initialList || []);

  return {
    passInfoList,
    editPassInfoList: doEdit,
    dragStartHandler,
    dragOverHandler,
    dragLeaveHandler,
    dragDropHandler,
    revert,
  };
}
