import { gql, useMutation } from '@apollo/client';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import style from './duration.module.css';
import InfoBackground from '../../../components/infoBackground/infoBackground';
import DurationForm, { DurationData } from './durationForm';

const EDIT_DURATION = gql`
mutation Mutation(
	$newPassInfo: PassInfoInput!,
	$passInfoId: String!
) {
  setPassInfo(
    newPassInfo: $newPassInfo
    passInfoId: $passInfoId
  ) {
    success
    error
  }
}
`;

type Props = {
  communityName?: string,
  portalName?: string,
  stepNum?: number,
}

export default function Duration(props: Props): ReactElement {
  const {
    communityName, portalName, stepNum,
  } = props;
  const history = useHistory();

  const { registrationId } = useParams<{ registrationId: string }>();
  const [doEdit, { error: editError }] = useMutation(EDIT_DURATION);
  const submitHandler = (durationData: DurationData) => {
    doEdit({
      variables: {
        newPassInfo: {
          durationInfo: {
            type: durationData.type,
            duration1: durationData.duration1,
            duration2: durationData.duration2,
          },
          capacityOverride: durationData.capacityOverride,
        },
        passInfoId: registrationId,
      },
    }).then(() => {
      history.push(`/pass-builder/${registrationId}/instructions`);
    });
  };

  return (
    <div className={style.container}>
      <InfoBackground
        title="Pass Builder"
        showBackBtn
        showPosition
        communityName={communityName}
        portalName={portalName}
      >
        <DurationForm
          registrationId={registrationId}
          showContinueBtn
          stepNum={stepNum}
          submitHandler={(durationData) => submitHandler(durationData)}
        />
      </InfoBackground>
    </div>
  );
}
Duration.defaultProps = {
  communityName: undefined,
  portalName: undefined,
  stepNum: undefined,
};
