import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InfoBackground from '../../../components/infoBackground/infoBackground';
import { passType } from './manageGqls';
import style from './managePasses.module.css';
import useManagePasses from './useManagePasses';
import { useGroupOrder } from './useGroupOrder';

export default function ManagePasses(): ReactElement {
  const {
    communityId,
    portalName,
    showOrder,
    setShowOrder,
    showOrderDetails,
    setShowOrderDetails,
    groups,
    refetchGroups,
    passMoveModeOn,
    activeGroup,
    setActiveGroup,
    activeGroupData,
    handleGroupDelete,
    // passList,
    handlePassToGroupRemoval,
    togglePassMoveMode,
    handleAddPassConfirm,
    allPassData,
    handleSelectedPassForGroup,
    handlePassRemoval,
  } = useManagePasses();

  const {
    passInfoList: activeGroupPassInfoList,
    editPassInfoList: saveGroupPassInfoListMutation,
    revert: revertGroupPassInfoListEdits,
    dragStartHandler,
    dragOverHandler,
    dragLeaveHandler,
    dragDropHandler,
  } = useGroupOrder(activeGroupData?.passInfoList);

  // console.log(activeGroupPassInfoList);
  // console.log(activeGroupData);
  // console.log(allPassData);
  return (
    <div>
      <InfoBackground
        title="Admin Pass List"
        showBackBtn
        showPosition
        hideLine
        communityName={communityId}
        portalName={portalName}
      />
      <div className={style.flexBox}>
        {/* right column  */}
        <div className={style.groupListPart}>
          <div className={style.groupListHeader}>
            <h4 className={style.passListTitle}>
              Group List
            </h4>
            <div className={style.passListBox}>
              <div className={showOrder ? style.sortOrderDone : style.sortOrderBtn}>
                <GenericButton
                  title={showOrder ? 'Done' : ' Sort Order'}
                  clickHandler={() => setShowOrder(!showOrder)}
                />

              </div>
              <div className={style.createGroupBtn}>
                <Link to={`/popup/${communityId}/${portalName}/create-group`}>
                  <GenericButton
                    title="Create A Group"
                  />
                </Link>
              </div>
            </div>
            {' '}
          </div>
          <div className={style.overflowBox}>
            <div className={style.groupItemsBox}>
              {groups.map((group) => (
                <button
                  key={group.passGroupId}
                  disabled={passMoveModeOn}
                  className={activeGroup === group.passGroupId ? style.active : style.inactive}
                  onClick={() => setActiveGroup(group.passGroupId)}
                >
                  <div className={style.nameAndOrderCircle}>
                    {showOrder ? (
                      <div className={style.orderCircle}>1</div>) : null}
                    <div className={style.groupName}>
                      {group.name}
                    </div>
                  </div>
                  <div className={activeGroup === group.passGroupId
                    ? style.lineActive : style.lineInactive}
                  />
                </button>
              ))}
            </div>
          </div>
        </div>
        {/* center column */}
        <div className={style.groupDitailsPart}>
          <div className={style.groupDetailsHeader}>
            <h4 className={style.passListTitle}>{activeGroupData?.name}</h4>
            <div className={style.groupDetailsBtnBox}>
              <div className={style.renameBtn}>
                <Link to={`/popup/${activeGroupData?.passGroupId}/rename-group?name=${activeGroupData?.name}`}>
                  <GenericButton
                    title="Rename Group"
                    disabled={!activeGroupData?.passGroupId}
                  />
                </Link>
              </div>
              <div className={style.dismissBtn}>
                <GenericButton
                  title="Delete Group"
                  disabled={!activeGroup}
                  clickHandler={() => {
                    if (activeGroup) {
                      handleGroupDelete(activeGroup);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className={style.groupDetailsBox}>
            <div className={style.groupDetailsTHeader}>
              {showOrderDetails ? (
                <div className={style.invisibleBox} />) : null}
              <p className={style.passName}>Pass Name</p>
              <p className={style.passStatus}>Pass Status</p>
              <div className={showOrderDetails
                ? style.sortOrderDetailBtnDone : style.sortOrderDetailBtn}
              >
                <GenericButton
                  title={showOrderDetails ? 'Save' : ' Sort Order'}
                  clickHandler={() => {
                    setShowOrderDetails(!showOrderDetails);
                    if (!showOrderDetails) return;
                    saveGroupPassInfoListMutation({
                      variables: {
                        passInfoList: activeGroupPassInfoList,
                        passGroupId: activeGroup,
                      },
                    })
                      .then((d) => {
                        if (d.data?.setPassGroupOrder.success) {
                          window.alert('Updated successfully!');
                        } else {
                          console.error(d);
                          window.alert('Something went wrong');
                          revertGroupPassInfoListEdits();
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                        window.alert('Something went wrong');
                        revertGroupPassInfoListEdits();
                      })
                      .finally(() => {
                        refetchGroups();
                      });
                  }}
                />

              </div>
            </div>
            <div className={style.groupDetailsItemsBox}>
              {activeGroupPassInfoList
                .map((pid, idx) => {
                  const pass = allPassData.find((p) => p.passInfoId === pid);
                  return (
                    <div
                      key={pass?.passInfoId}
                      className={style.groupDetailsItem}
                      draggable={showOrderDetails}
                      onDragStart={dragStartHandler(pass?.passInfoId || '', idx)}
                      onDragOver={dragOverHandler(idx === activeGroupPassInfoList.length - 1)}
                      onDragLeave={dragLeaveHandler}
                      onDrop={dragDropHandler(pass?.passInfoId || '', idx)}
                    >
                      {showOrderDetails ? (
                        <div className={style.orderCircleBig}>{idx + 1}</div>) : null}
                      <div className={style.detailsItemName}>
                        {pass?.name || 'COULD NOT FIND PASS'}
                      </div>
                      <div className={pass?.complete
                        ? style.detailsItemStatusActive : style.detailsItemStatusInactive}
                      >
                        {pass?.complete ? 'Active' : 'InActive'}
                      </div>
                      {(pass?.passInfoId && !showOrderDetails) && (
                      <>
                        <Link to={`/pass-builder/${pass.passInfoId}/review`}>
                          <div className={style.editBtn}>
                            <GenericButton
                              title="Edit"
                            />

                          </div>
                        </Link>
                        <div className={style.removeBtn}>
                          <GenericButton
                            title=""
                            clickHandler={() => {
                              handlePassToGroupRemoval(pass.passInfoId);
                            }}
                          />

                        </div>
                      </>
                      )}
                    </div>
                  );
                })}

            </div>

          </div>
          <div className={style.addPassesBtnBox}>
            <div className={style.addPassesBtn}>
              {
                !passMoveModeOn && (
                  <GenericButton
                    title="Add Passes +"
                    // disabled={!activeGroupData?.passGroupId}
                    clickHandler={() => togglePassMoveMode(true)}
                  />
                )
              }
              {
                passMoveModeOn && (
                  <>
                    <div className={style.cancel}>
                      <GenericButton
                        title="Cancel"
                        clickHandler={() => togglePassMoveMode(false)}
                      />
                    </div>
                    <div className={style.confirm}>
                      <GenericButton
                        title="Confirm"
                        clickHandler={handleAddPassConfirm}
                        color="grey"
                      />
                    </div>
                  </>
                )
              }

            </div>

          </div>
        </div>
        {/* right column */}
        <div className={style.allPassesPart}>
          <div className={style.allPassesHeader}>
            <h4 className={style.passListTitle}>
              List of All Passes

            </h4>

          </div>
          <div className={style.unassignedTitle}>
            Unassigned Passes-Please add to a
            Group to Activate and display pass

          </div>
          <div className={style.unassignedBox}>
            <div className={style.groupDetailsTHeader}>
              <p className={style.passName}>Pass Name</p>
              <p className={style.passStatus}>Pass Status</p>
            </div>
            <div className={style.groupDetailsItemsBox}>
              {
                allPassData.length > 0
                && allPassData.map(
                  (pass: passType) => (
                    <div
                      className={style.groupDetailsItem}
                      key={pass.passInfoId}
                    >
                      {
                        passMoveModeOn && (
                          <div className={style.checkBox}>
                            <input onChange={(e) => handleSelectedPassForGroup(e, pass)} type="checkbox" />
                          </div>
                        )
                      }
                      {/* {showOrderDetails ? (
                        <div className={style.orderCircleBig}>1</div>) : null} */}
                      <div className={style.detailsItemName}>
                        {pass.name}
                      </div>
                      <div className={pass.complete
                        ? style.detailsItemStatusActive : style.detailsItemStatusInactive}
                      >
                        {pass.complete ? 'Active' : 'InActive'}
                      </div>
                      <Link to={`/pass-builder/${pass.passInfoId}/review`}>
                        <div className={style.editBtn}>
                          <GenericButton
                            title="Edit"
                          />

                        </div>
                      </Link>
                      <div className={style.removeBtn}>
                        <GenericButton
                          title=""
                          clickHandler={() => {
                            handlePassRemoval(pass.passInfoId);
                          }}
                        />
                      </div>
                    </div>
                  ),
                )
              }
            </div>
          </div>
          <div className={style.unassignedTitle}>
            Assigned Passes

          </div>
          <div className={style.unassignedBox}>
            <div className={style.groupDetailsTHeader}>
              <p className={style.passName}>Pass Name</p>
              <p className={style.passStatus}>Pass Status</p>
            </div>

            {/* <div className={style.groupDetailsItemsBox}>
              {passesList1.map((p) => (
                <div
                  className={style.groupDetailsItem}
                  key={p.passInfoId}
                >
                  <div className={style.detailsItemName}>
                    {p.name}

                  </div>
                  <div className={p.status === 'Active' ? style.detailsItemStatusActive
                  : style.detailsItemStatusInactive}>
                    {p.status}
                  </div>
                  <div className={style.editBtn}>
                    <GenericButton
                      title="Edit"
                    />

                  </div>
                  <div className={style.removeBtn}>
                    <GenericButton
                      title=""
                    />

                  </div>
                </div>
              ))}

            </div> */}

          </div>
        </div>

      </div>
    </div>
  );
}
ManagePasses.defaultProps = {
  communityName: undefined,
  portalName: undefined,
};
